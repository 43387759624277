import { render, staticRenderFns } from "./nice-checkbox.vue?vue&type=template&id=f70a0ee8&scoped=true"
import script from "./nice-checkbox.vue?vue&type=script&lang=js"
export * from "./nice-checkbox.vue?vue&type=script&lang=js"
import style0 from "./nice-checkbox.vue?vue&type=style&index=0&id=f70a0ee8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70a0ee8",
  null
  
)

export default component.exports