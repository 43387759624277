import { render, staticRenderFns } from "./bell.vue?vue&type=template&id=7d367018&scoped=true"
import script from "./bell.vue?vue&type=script&lang=js"
export * from "./bell.vue?vue&type=script&lang=js"
import style0 from "./bell.vue?vue&type=style&index=0&id=7d367018&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d367018",
  null
  
)

export default component.exports