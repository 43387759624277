import { render, staticRenderFns } from "./ni-icon-cancel.vue?vue&type=template&id=67b78a61"
import script from "./ni-icon-cancel.vue?vue&type=script&lang=js"
export * from "./ni-icon-cancel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports