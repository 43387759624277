<template>
		<section class="layout-full_width" >
			<header class="header">
				<h1 class="title">
					{{ $t('users.agency_list') | capitalize }}
				</h1>

				<div class="top-panel">

					<invitation-create-modal
						v-if="isAgencyInvitationModalShowing"
						:title="$t('users.invite_agency')"
						:is-sent="isAgencyInvitationSent"

						@send-invitation="sendAgencyInvitation"
						@close="isAgencyInvitationModalShowing = false"
					/>

					<nice-button-2
						icon="plus-24"
						class="add-button"
						icon-painted
						@click="isAgencyInvitationSent = false; isAgencyInvitationModalShowing = true"
					>
						{{ $t('users.invite_agency') }}
					</nice-button-2>

					<nice-button-2
						v-if="isSuperSSP"
						icon="plus-24"
						class="add-button"
						icon-painted
						@click="$router.push({ name: `${ resource }-create` })"
					>
						{{ $t('users.add_agency') }}
					</nice-button-2>

					<nice-input
						v-model="filter.name"
						class="search-input"
						type="text"
						:placeholder="$t('users.search') | capitalize"
					>
						<nice-icon
							icon="search"
						/>
					</nice-input>
				</div>
			</header>

			<nice-users-list
				:items="filteredList"

				@interact="interactAgencyOrInvitationHandler"
				@remove="removeAgencyOrInvitationHandler"
				@restore="restoreAgencyHandler"
			/>

		</section>
</template>

<script>
import { mapState } from 'vuex';

import {
	requestChildAgencies,

	// TODO: SSP Super Admin only?
	deleteChildAgency,
	restoreChildAgency,
} from '@/api/agency';
import {
	requestInvitations,
	createInvitation,
	deleteInvitation,
	INVITATION_ROLE_DSP_AGENCY,
} from '@/api/invitation';


// TODO: an abstraction. do we need it?
import NiceUsersList from '@/ui/nice-users/nice-users-list';
import NiceInput from '@/ui/nice-input';
import InvitationCreateModal from '@/components/users/invitation-create-modal';


export default {
	name: 'PageAgencyList',

	components: {
		NiceUsersList,
		NiceInput,
		InvitationCreateModal,
	},

	props: {

		// TODO: an abstraction. do we need it?
		resource: {
			type: String,
			default: 'agency',
		},
	},

	data() {
		return {
			filter: {
				name: '',
			},
			resourceList: [],

			isAgencyInvitationSent: false,
			isAgencyInvitationModalShowing: false,
		};
	},


	computed: {
		...mapState('app', {
			currentAgencyId: 'agency',
		}),
		...mapState('profile', {
			isSuperSSP: 'is_super',
		}),

		/**
		 * List with applyed filter
		 * @return {object[]} items
		 */
		filteredList() {
			return this.resourceList.filter(item => {
				const filterString = this.filter.name.toLowerCase();
				const testStrings = [];

				// collect string to test
				if (item.first_name) {
					testStrings.push(item.first_name);

					if (item.last_name) {
						testStrings.push(item.last_name);
						testStrings.push(`${item.last_name} ${item.first_name}`);
					}
				} else if (item.last_name) {
					testStrings.push(item.last_name);
				}

				if (item.detail) {
					testStrings.push(item.detail);
				}

				return testStrings.some(string => string.toLowerCase().search(filterString) !== -1);
			});
		},
	},


	methods: {
		/**
		 * Remove Agency or Invitation item
		 *
		 * TODO:
		 *
		 * @async
		 * @param {number} id - item id
		 */
		async removeAgencyOrInvitationHandler(item, event) {
			event.preventDefault();
			event.stopPropagation();

			let confirmationMessage = this.$t('users.confirm_agency_deletion');
			let failedDeletionMessage = this.$t('users.failed_to_delete_agency');
			if (item.type == 'invitation') {
				confirmationMessage = this.$t('invitation.confirm_invitation_deletion');
				failedDeletionMessage = this.$t('invitation.failed_to_delete_invitation');
			}

			if (!window.confirm(confirmationMessage)) {
				return;
			}

			try {
				if (item.type == 'invitation') {
					// we need to pass role for permissions check
					await deleteInvitation(this.currentAgencyId, item.id, INVITATION_ROLE_DSP_AGENCY);
				}
				else {
					await deleteChildAgency(item.id);
				}

				// reget list
				this.$set(this, 'resourceList', await this.getList());
			}
			catch (err) {
				this.$log.error(err);
				window.alert(failedDeletionMessage);
			}
		},


		/**
		 * Interact handler
		 * In fact it is a click handler for the item
		 *
		 * @param {object} item - item
		 */
		interactAgencyOrInvitationHandler(item) {
			if (item.type == 'agency') {
				this.$router.push({
					name: `${this.resource}-details`,
					params: { id: item.id },
				});
			}
		},


		/**
		 * Restore Agency
		 *
		 * @async
		 * @param {number} id - item id
		 */
		async restoreAgencyHandler(item, event) {
			event.preventDefault();
			event.stopPropagation();

			if (!window.confirm(this.$t('users.confirm_agency_restoration'))) {
				return;
			}

			try {
				await restoreChildAgency(item.id);

				// reget list
				this.$set(this, 'resourceList', await this.getList());
			}
			catch (err) {
				this.$log.error(err);
				window.alert(this.$t('users.failed_to_restore_agency'));
			}
		},


		/**
		 * Get agencies and agency invitations list
		 *
		 * @async
		 * @return {object[]} combined list of agencies and invitations
		 */
		async getList() {
			const agenciesList = await requestChildAgencies();
			const invitationsList = await requestInvitations(INVITATION_ROLE_DSP_AGENCY);

			return [
				...agenciesList.map(this.agencySerializer),
				...invitationsList.map(this.invitationSerializer),
			];
		},

		/**
		 * Agency serializer
		 *
		 * we need to adapt to the nice-users-list
		 */
		agencySerializer(item) {
			let dsp_networks = item.dsp_networks.map((i) => i.name).join(', ');

			return {
				id: item.id,
				type: 'agency',
				name: item.name,
				first_name: item.name,
				last_name: null,
				detail: `${this.$t('common.platforms')}:  ${dsp_networks}`,
				is_deleted: item.is_deleted,
				isRemoveAllowed: this.isSuperSSP,
			};
		},

		/**
		 * Invitation serializer
		 *
		 * we need to adapt to the nice-users-list
		 */
		invitationSerializer(item) {
			return {
				id: item.id,
				type: 'invitation',
				name: item.email,
				first_name: `${item.email} (${this.$t('invitation.invitation')})`,
				last_name: null,
				detail: `${this.$t('common.expires')}  ${item.expiration_date}`,
				is_deleted: false,
			};
		},

		/**
		 * Send Agency Invitation
		 *
		 * Invitation role = dsp_agency
		 *
		 */
		async sendAgencyInvitation(email) {
			if (!email) {
				this.$log.info('no email');
				return;
			}

			try {
				let res = await createInvitation(
					this.currentAgencyId,
					email,
					INVITATION_ROLE_DSP_AGENCY
				);

				if (!res) {
					throw this.$t('invitation.email_failed');
				}

				this.isAgencyInvitationSent = true;

				// reget list
				this.$set(this, 'resourceList', await this.getList());
			}
			catch (error) {
				let message = this.$t('invitation.failed');
				if(error && error.response && error.response.data && error.response.data.error) {
					// translations are on the backend
					message = error.response.data.error;
				}

				alert(message);
			}
		},
	},  // END: methods


	async mounted() {
		// TODO: on page reload we don't have an agency here
		//       but this way we can have 2 requests
		this.$set(this, 'resourceList', await this.getList());
	},
};
</script>

<style lang="sass" scoped >
.search-input
	width: 200px

.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px

	& > *:not(:first-child)
		margin-left: 20px
</style>
