<template>
	<section class="nice-wizard-2" >
		<header class="ni_wizard_2--navigation" >
			<template
				v-for="(step, i) in getStepList()"
			>
				<span
					:key="step.slug"
					class="ni_wizard_2--navigation-step"
					:class="{
						'current': stepCurrent === i,
						'entered': stepCurrent > i,
					}"
				>
					<span class="ni_wizard_2--navigation-step-number" >{{ i+1 }}</span>
					<span
						class="ni_wizard_2--navigation-step-name"
						v-html="stepTitle(step)"
					/>
				</span>

				<span
					v-if="i < getStepCount() - 1"
					:key="i"
					class="ni_wizard_2--navigation-separator"
				/>
			</template>
		</header>

		<NiStep
			:vnode="getStep(stepCurrent)"
			class="ni_wizard_2--content"
			:stepCurrent="stepCurrent"
			:stepCount="getStepCount()"
			:goToNext="stepNext"
			:goToPrev="stepPrev"
		/>
	</section>
</template>

<script>
//
// We use methods for steps to make things rerender in `NiStep`
//
// NB: reactive work here is very awkward.
//

import _ from 'underscore';

import VnodeRenderer from '@/ui/vnode-renderer';

const getAttr = (vnode, attr) => vnode.data && vnode.data.attrs ? vnode.data.attrs[attr] : null;


export default {
	name: 'NiceWizard2',


	components: {
		NiStep: VnodeRenderer,
	},


	props: {
		/**
		 * Initial step index from 0
		 */
		stepInitial: {
			type: Number,
			default: 0,
		},

		/**
		 * Hooks
		 */
		preNextStepHook: {
			type: Function,
			default: () => {},
		},

		prePrevStepHook: {
			type: Function,
			default: () => {},
		},
	},


	data() {
		return {
			stepCurrent: this.stepInitial || 0,
		};
	},


	mounted() {
		// check step in the hash and go there
		if (this.$route.hash && this.$route.hash.indexOf('step') === 1) {
			let goToStep = parseInt(this.$route.hash.replace('#', '').replace('step', ''));
			this.goToStep(goToStep);
		}
	},


	methods: {
		getStep(stepIndex) {
			return this.getSteps()[stepIndex];
		},

		/**
		 * Steps List
		 *
		 * MUST be a method
		 */
		getStepList() {
			return this.getSteps().map((vnode, index) => {

				return {
					name: getAttr(vnode, 'data-ni_wizard_2-name') || 'Step',
					name_trans_key: getAttr(vnode, 'data-ni_wizard_2-name_trans_key'),
					slug: getAttr(vnode, 'data-ni_wizard_2-slug') || `step_${index}`,
					vnode: vnode
				};
			});
		},

		/**
		 * Filter empty nodes (comments and texts) out
		 *
		 * MUST be a method
		 */
		getSteps() {
			return _.filter(this.$scopedSlots.default(), vnode => vnode.tag);
		},

		/**
		 * Total steps count
		 *
		 * MUST be a method
		 */
		getStepCount() {
			return this.getSteps().length;
		},

		/**
		 * Goto step by index
		 */
		goToStep(stepNext) {
			let oldCurrent = this.stepCurrent;
			let newCurrent = Math.min(this.getStepCount() - 1, Math.max(0, stepNext));

			let eventName = '';
			if (newCurrent == oldCurrent + 1) {
				eventName = 'next-step';
			}
			else if (newCurrent == oldCurrent - 1) {
				eventName = 'prev-step';
			}
			else if (newCurrent != oldCurrent) {
				eventName = 'to-step';
			}

			let promise = null;

			// pre next step hook
			if (eventName == 'next-step') {
				promise = this.preNextStepHook(newCurrent, oldCurrent, this);
			}
			// pre prev step hook
			else if (eventName == 'prev-step') {
				promise = this.prePrevStepHook(newCurrent, oldCurrent, this);
			}

			(Promise.resolve(promise)).then(() => {
				this.stepCurrent = newCurrent;

				// update step hash if needed
				if (this.$route.hash != `#step${this.stepCurrent}`) {
					this.$router.push({
						...this.$route,
						hash: `#step${this.stepCurrent}`,
					});
				}

				this.$log.info(`Moved to step ${newCurrent}`);

				// post event
				if (eventName) {
					this.$emit(eventName, newCurrent, oldCurrent, this);
				}
			});
			// .catch((err) => {
			// 	this.$log.error(`Failed to move to step ${newCurrent}: ${err}`);
			// });
		},

		stepTitle(step) {
			const title = this.$t(step.name_trans_key) || step.name;
			return this.$options.filters.capitalize(title);
		},

		/**
		 * Goto next step
		 */
		stepNext() {
			this.goToStep(this.stepCurrent + 1);
		},

		/**
		 * Goto previous step
		 */
		stepPrev() {
			this.goToStep(this.stepCurrent - 1);
		},
	},
};
</script>


<style lang="sass" scoped>
.ni_wizard_2--navigation
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: center
	align-items: center
	align-content: center

	margin-bottom: 30px
	width: 100%

.ni_wizard_2--navigation-step
	flex-grow: 0
	flex-shrink: 0

	.ni_wizard_2--navigation-step-number
		$size: 30px

		display: inline-block

		width: $size
		height: $size

		font-size: 16px
		line-height: $size
		border: 2px solid $nice_color-gray
		border-radius: 50%
		text-align: center
		color: $nice_color-gray_dark

	.ni_wizard_2--navigation-step-name
		margin-left: 11px

		color: $nice_color-gray_dark

	&.current,
	&.entered
		.ni_wizard_2--navigation-step-number
			border-color: var(--main_color)
			color: var(--main_color)

		.ni_wizard_2--navigation-step-name
			color: var(--text_color)

.ni_wizard_2--navigation-separator
	flex-grow: 1
	flex-shrink: 1

	display: inline-block

	margin: 0 20px
	flex-grow: 1
	flex-shrink: 1
	max-width: 96px
	height: 1px

	background-color: $nice_color-gray
</style>
