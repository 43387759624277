import { render, staticRenderFns } from "./tab-pallete.vue?vue&type=template&id=62e82741&scoped=true"
import script from "./tab-pallete.vue?vue&type=script&lang=js"
export * from "./tab-pallete.vue?vue&type=script&lang=js"
import style0 from "./tab-pallete.vue?vue&type=style&index=0&id=62e82741&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62e82741",
  null
  
)

export default component.exports