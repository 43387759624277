<template>
	<main class="app-settings-page">
		<div class="panel">
			<h1>{{ $t('agency.whitelabel_settings') | capitalize }}</h1>

			<nice-checkbox
				v-model="instance.is_whitelabel_enabled"
				:caption="$t('agency.is_whitelabel_enabled')"

				@input="patchMethod('is_whitelabel_enabled', $event)"
			/>

			<div class="panel-container">
				<div class="settings">
					<h2>{{ $t('agency.basic') | capitalize }}</h2>

					<nice-input
						v-model="instance.name"
						class="input-field"
						:label="$t('agency.name') | capitalize"

						@change="patchMethod('name', $event.target.value)"
					/>

					<nice-select
						v-model="instance.whitelabel_default_language"
						class="select-field"
						:options="languageOptions"
						:label="$t('agency.default_language') | capitalize"

						@input="patchMethod('whitelabel_default_language', $event)"
					/>

					<nice-select
						v-model="instance.enabled_payment_models"
						class="input-field"
						labeled
						:label="$t('agency.enabled_payment_models') | capitalize"
						:options="paymentModelOptions"
						multiple

						@input="patchMethod('enabled_payment_models', $event)"
					/>

					<nice-input
						v-model="instance.default_balance"
						:label="$t('agency.default_balance') | capitalize"
						:invalid="getError('default_balance')"
						class="input-field"
						type="number"
						step="1"
						:min="0"

						@change="patchMethod('default_balance', $event.target.value)"
					/>

					<nice-input
						v-model="instance.domain"
						:label="$t('agency.domain') | capitalize"
						:invalid="getError('domain')"
						class="input-field"

						@change="patchMethod('domain', $event.target.value)"
					/>

					<p
						v-if="instance.certificate"
						class="certificate-info"
					>
						{{ $t('agency.certificate_status') }}: {{ $t(`agency.certificate_status_${instance.certificate.status}`) }}
					</p>

					<div
						class="dns-settings-info"
						v-html="$t('agency.whitelabel_dns_settings_info', { whitelabel_ip: whitelabelIp })"
					/>

					<!-- :mask="domainMask" -->
					<p class="help-info">{{ $t('agency.help_text') | capitalize }} </p>
				</div>

				<div class="settings">
					<h2>{{ $t('agency.html_title') | capitalize }}</h2>
					<nice-select
						v-model="language"
						class="select-field"
						:options="languageOptions"
					/>
					<nice-input
						v-model="instance[`html_title_${language}`]"
						:label="$t('agency.html_title') | capitalize"
						class="input-field"

						@change="patchMethod(`html_title_${language}`, $event.target.value)"
					/>

					<h2>{{ $t('agency.description') | capitalize }}</h2>
					<nice-select
						v-model="language"
						class="select-field"
						:options="languageOptions"
					/>
					<nice-input
						v-model="instance[`html_description_${language}`]"
						class="input-field"
						:label="$t('agency.description') | capitalize"
						@change="patchMethod(`html_description_${language}`, $event.target.value)"
					/>

				</div>

				<div class="settings">
					<h2>{{ $t('agency.visual') | capitalize }}</h2>
					<image-uploader
						:label="$t('agency.logo') | capitalize"
						:image-url="instance.logo"
						:upload-method="uploadMethod('logo')"
						accept="image/jpeg,image/png,image/gif"
						:resolution="{width: 105, height: 105}"
					/>
					<p class="image-info">*105х105px, JPG, PNG, GIF</p>

					<image-uploader
						:label="$t('agency.favicon') | capitalize"
						:image-url="instance.favicon"
						:upload-method="uploadMethod('favicon')"
						accept="image/png,image/vnd.microsoft.icon"
						:resolution="{width: 105, height: 105}"
						class="icon-uploader"
					/>
					<p class="image-info">*105х105px, PNG, ICO</p>

					<div class="input-color">
						<nice-icon-2
							icon="drop"
							class="drop-icon"
							:style="`--ni-icon-sign: #${instance.main_color}`"
						/>
						<nice-input
							v-model="instance.main_color"
							placeholder="2dc3b7"
							:mask="colorMask"
							class="color-field"
							:label="$t('agency.main_color') | capitalize"

							@change="patchMethod('main_color', '#' + $event.target.value)"
						>
							<nice-button-2
								icon="x"
								style="--ni-icon-sign: #000000"
								@click="patchMethod('main_color', null)"
							/>
						</nice-input>
					</div>

					<div class="input-color">
						<nice-icon-2
							icon="drop"
							class="drop-icon"
							:style="`--ni-icon-sign: #${instance.secondary_color}`"
						/>
						<nice-input
							v-model="instance.secondary_color"
							placeholder="0263cc"
							:mask="colorMask"
							class="color-field"
							:label="$t('agency.interface_color') | capitalize"

							@change="patchMethod('secondary_color', '#' + $event.target.value)"
						>
							<nice-button-2
								icon="x"
								style="--ni-icon-sign: #000000"
								@click="patchMethod('secondary_color', null)"
							/>
						</nice-input>
					</div>
				</div>

			</div>
		</div>

		<div class="panel">
			<h1>{{ $t('agency.email_settings') | capitalize }}</h1>

			<div class="panel-container">
				<div class="settings">
					<h2>{{ $t('agency.welcome_text') | capitalize }}</h2>
					<nice-select
						v-model="language"
						class="select-field"
						:options="languageOptions"
					/>
					<nice-input
						v-model="instance[`invitation_email_welcome_text_${language}`]"
						class="input-field"

						@change="patchMethod(`invitation_email_welcome_text_${language}`, $event.target.value)"
					/>
				</div>

				<div class="settings">
					<h2>{{ $t('agency.footer') | capitalize }}</h2>
					<nice-select
						v-model="language"
						class="select-field"
						:options="languageOptions"
					/>
					<nice-input
						v-model="instance[`email_footer_text_${language}`]"
						class="input-field"

						@change="patchMethod(`email_footer_text_${language}`, $event.target.value)"
					/>
				</div>

				<div class="settings">
					<h2>{{ $t('agency.visual') | capitalize }}</h2>
					<image-uploader
						:label="$t('agency.main_image') | capitalize"
						:image-url="instance.invitation_email_image"
						:upload-method="uploadMethod('invitation_email_image')"
						accept="image/jpeg,image/png,image/gif"
						:resolution="{width: 515, height: 330}"
					/>
					<p class="image-info">*515х330px, JPG, PNG, GIF</p>

					<image-uploader
						:label="$t('agency.footer_logo') | capitalize"
						:image-url="instance.email_footer_logo"
						:upload-method="uploadMethod('email_footer_logo')"
						accept="image/png,image/vnd.microsoft.icon"
						:resolution="{width: 105, height: 105}"
						class="icon-uploader"
					/>
					<p class="image-info">*105х105px, PNG, ICO</p>
				</div>
			</div>
		</div>


		<div class="panel">
			<div class="panel-container">
				<div class="settings">
					<h2>{{ $t('agency.tos') | capitalize }}</h2>

					<nice-select
						v-model="language"
						class="select-field"
						:options="languageOptions"
					/>
					<textarea
						v-model="instance[`tos_caption_${language}`]"
						class="tos-textarea"
						rows="7"
						@change="patchMethod(`tos_caption_${language}`, $event.target.value)"
					/>
				</div>

				<!-- <span
					class="tos-preview"
				>
					{{ instance[`tos_caption_${language}`] }}
				</span> -->

				<div class="tos-file-uploader-wrapper">
					<nice-label
						:label="$t('agency.tos_file_label')"
						class="tos-label"
					/>
					<a
						v-if="instance.tos_file"
						:href="instance.tos_file"
						target="_blank"
						class="tos-file-preview"
					>
						{{ $t('agency.tos_file_preview') }}
					</a>
					<nice-file
						ref="fileInput"
						class="tos-file-uploader"
						watercolor
						icon="load"
						icon-placement="start"
						icon-state="180"
						accept="application/pdf"
						@change="(event) => uploadPdf(event, 'tos_file')"
					> {{ $t('upload') | capitalize }}
					</nice-file>
					<p class="image-info">PDF</p>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-container">
				<div class="settings">
					<h2>{{ $t('agency.creative_docs') | capitalize }}</h2>

					<div class="tos-file-uploader-wrapper">
						<nice-label
							:label="$t('agency.creative_specification_label')"
							class="tos-label"
						/>
						<a
							v-if="instance.creative_specification_file"
							:href="instance.creative_specification_file"
							target="_blank"
							class="tos-file-preview"
						>
							{{ $t('agency.creative_specification_label') }}
						</a>
						<nice-file
							ref="fileInputSpecification"
							class="tos-file-uploader"
							watercolor
							icon="load"
							icon-placement="start"
							icon-state="180"
							accept=".pdf"
							@change="(event) => uploadPdf(event, 'creative_specification_file')"
						>
							{{ $t('upload') | capitalize }}
						</nice-file>
						<p class="image-info">PDF</p>
					</div>

					<div class="tos-file-uploader-wrapper">
						<nice-label
							:label="$t('agency.regulatory_docs_label')"
							class="tos-label"
						/>
						<a
							v-if="instance.regulatory_docs_file"
							:href="instance.regulatory_docs_file"
							target="_blank"
							class="tos-file-preview"
						>
							{{ $t('agency.regulatory_docs_label') }}
						</a>
						<nice-file
							ref="fileInputRegulatory"
							class="tos-file-uploader"
							watercolor
							icon="load"
							icon-placement="start"
							icon-state="180"
							accept=".pdf"
							@change="(event) => uploadPdf(event, 'regulatory_docs_file')"
						>
							{{ $t('upload') | capitalize }}
						</nice-file>
						<p class="image-info">pdf</p>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<h1>{{ $t('agency.help_title') }}</h1>

			<div class="panel-container">
				<div class="settings">
					<nice-input
						v-model="instance.help_text"
						:label="$t('description')"
						:invalid="getError('help_text')"
						class="input-field"

						@change="patchMethod('help_text', $event.target.value)"
					/>

					<nice-input
						v-model="instance.help_email"
						:label="$t('email')"
						:invalid="getError('help_email')"
						class="input-field"

						@change="patchMethod('help_email', $event.target.value)"
					/>

					<nice-input
						v-model="instance.help_phone"
						:label="$t('phone')"
						:invalid="getError('help_phone')"
						:validator="phoneValidate"

						class="input-field"

						@change="patchMethod('help_phone', $event.target.value)"
					/>
				</div>
			</div>
		</div>

		<div class="panel">
			<h1>{{ $t('agency.schedule_title') }}</h1>

			<div class="panel-container">
				<div class="settings">
					<TimeRange
						v-model="instance.time_range"
						class="input--time-range"

						@input="patchMethod('time_range', $event)"
					/>

					<!-- duration & rate -->
					<section class="duration-n-rate">
						<nice-input
							v-model.number="instance.ad_duration"
							:label="$t('campaign.message_duration') | capitalize"
							:invalid="getError('ad_duration')"
							type="number"
							min="1"
							max="300"
							step="1"
							:units="$t('campaign.sec')"
							class="input--duration"

							@change="patchMethod('ad_duration', $event.target.value === '' ? null : $event.target.value)"
						/>

						<div>
							<nice-label
								:label="$t('campaign.frequency_limit') | capitalize"
								:hint="$t('campaign.per_device') | capitalize"
								class="ad-rate__label"
							/>
							<div class="ad-rate__input">
								<nice-input
									v-model.number="instance.ad_rate_per_hour"
									type="number"
									min="1"
									max="720"
									step="1"
									class="frequency-limit"

									@change="saveAdRatePerHour($event.target.value)"
								/>
								<nice-select
									v-model="instance.ad_rate_multiplier"
									:options="adRateOptions"
									class="ad-rate__modifier"

									@input="saveAdRateMultiplier($event)"
								/>
							</div>
						</div>
					</section>
					<!-- END: duration & rate -->
				</div>
			</div>
		</div>

		<div class="panel">
			<h1>{{ $t('agency.seo_title') }}</h1>

			<div class="panel-container">
				<div class="settings">
					<nice-input
						v-model="instance.yandex_metrika_id"
						:label="$t('agency.yandex_metrika_id')"
						:invalid="getError('yandex_metrika_id')"
						:validator="numbersValidate"
						class="input-field"

						@change="patchMethod('yandex_metrika_id', $event.target.value)"
					/>
				</div>
			</div>
		</div>
	</main>
</template>


<script>
import { mapState } from 'vuex';

import { requestAgencySettings, patchAgencySettings } from '@/api/agency';

import NiceInput from '@/ui/nice-input';
import NiceSelect from '@/ui/nice-select';
import NiceFile from '@/ui/nice-file';
import NiceCheckbox from '@/ui/nice-checkbox';
import ImageUploader from '@/components/image-uploader.vue';
import TimeRange from '@/components/time-range.vue';
import { phoneValidator, numbersValidator } from '@/ui/utils/validators';

import { DEFAULT_LOCALE } from '@/constants/locale.js';
import {
	PAYMENT_MODEL_AD_PLAYS,
	PAYMENT_MODEL_CPM,
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';


export default {
	name: 'AppSettingsPage',


	components: {
		NiceFile,
		NiceInput,
		NiceSelect,
		NiceCheckbox,
		ImageUploader,
		TimeRange
	},


	data() {
		return {
			language: DEFAULT_LOCALE,
			whitelabelIp: process.env.VUE_APP_WHITELABEL_IP,
			paymentModelOptions: [
				{
					value: PAYMENT_MODEL_AD_PLAYS,
					label: this.$t('payment_models.ad_plays'),
				},
				{
					value: PAYMENT_MODEL_CPM,
					label: this.$t('payment_models.cpm'),
				},
				{
					value: PAYMENT_MODEL_CPV,
					label: this.$t('payment_models.cpv'),
				},
			],

			instance: {
				name: '',
				domain: '',
				languages: [],
				html_title: '',
				html_description: '',
				invitation_email_welcome_text: '',
				email_footer_text: '',
				main_color: '2CC3B7',
				secondary_color: '0263CC',
				email_footer_logo: null,
				favicon: null,
				invitation_email_image: null,
				logo: null,
				tos_caption: '',
				tos_file: null,
				help_text: '',
				help_email: '',
				help_phone: '',
				is_whitelabel_enabled: false,
				whitelabel_default_language: DEFAULT_LOCALE,
				enabled_payment_models: [
					PAYMENT_MODEL_AD_PLAYS,
					PAYMENT_MODEL_CPM,
					PAYMENT_MODEL_CPV,
				],
				time_range: [0, 24 * 60 * 60 - 1],
				ad_duration: 15,
				ad_rate_per_hour: 60,
				ad_rate_multiplier: 1,
			},
			error: null,

			colorMask: '[******]',
			// domainMask: ''
		};
	},


	async created() {
		await this.getSettings();
	},


	computed: {
		...mapState('profile', {
			profileLanguage: 'language',
		}),


		languageOptions() {
			return this.instance.languages.map(i => {
				return {
					value: i[0],
					label: i[1],
				};
			});
		},


		adRateOptions() {
			return Object.freeze([
				{
					label: this.$t('campaign.for_hour', { value: 1 }),
					value: 1,
				},
				{
					label: this.$t('campaign.for_minutes', { value: 30 }),
					value: 2,
				},
				{
					label: this.$t('campaign.for_minutes', { value: 10 }),
					value: 6,
				},
				{
					label: this.$t('campaign.for_minutes', { value: 5 }),
					value: 12,
				},
				{
					label: this.$t('campaign.for_minutes', { value: 2 }),
					value: 30,
				},
			]);
		},
	},


	methods: {
		async getSettings() {
			const res = await requestAgencySettings();
			this.setInstance(res);
		},


		uploadMethod(field) {
			return async (file) => {
				const config={
					headers: { 'Content-Type': 'multipart/form-data' },
				};
				const res = await patchAgencySettings({ [field]: file }, config);
				this.setInstance(res);
			};
		},

		async patchMethod(field, value) {
			this.patchMethodMultiFields({ [field]: value });
		},

		async patchMethodMultiFields(fields) {
			let res = {};
			const config={
				headers: { 'Content-Type': 'application/json' },
			};

			try {
				const data = fields;
				res = await patchAgencySettings(data, config);
				this.setInstance(res);
			}
			catch (error) {
				this.error = error.response.data;
				throw error;
			}
			this.error = null;
		},

		async saveAdRatePerHour(ad_rate_per_hour) {
			if (ad_rate_per_hour === '') {
				ad_rate_per_hour = null;
			} else {
				ad_rate_per_hour = ad_rate_per_hour * this.instance.ad_rate_multiplier;
			}
			this.patchMethod('ad_rate_per_hour', ad_rate_per_hour);
		},

		saveAdRateMultiplier(ad_rate_multiplier) {
			const ad_rate_per_hour = this.instance.ad_rate_per_hour * ad_rate_multiplier;
			this.patchMethodMultiFields({
				'ad_rate_multiplier': ad_rate_multiplier,
				'ad_rate_per_hour': ad_rate_per_hour,
			});
		},

		setInstance(inst) {
			if (inst.main_color[0] === '#') {
				inst.main_color = inst.main_color.slice(1);
			}
			if (inst.secondary_color[0] === '#') {
				inst.secondary_color = inst.secondary_color.slice(1);
			}
			if (inst.ad_rate_per_hour != null) {
				inst.ad_rate_per_hour = inst.ad_rate_per_hour / inst.ad_rate_multiplier;
			}
			this.$set(this, 'instance', inst);
		},


		async uploadPdf(file, field_name) {
			const config={
				headers: { 'Content-Type': 'multipart/form-data' },
			};
			await patchAgencySettings({[field_name]: file[0]}, config);
		},

		getError(field) {
			if (this.error && this.error[field] && this.error[field][0]) {
				return this.error[field][0];
			}
			return null;
		},

		phoneValidate(value) {
			return phoneValidator(value);
		},

		numbersValidate(value) {
			return numbersValidator(value);
		},
	},  // END: methods
};
</script>


<style lang="sass" scoped>
.app-settings-page
	padding-bottom: 50px

.panel
	margin: 40px

h1
	font-size: 18px
	line-height: 21px
	margin-bottom: 30px
	font-weight: normal

h2
	font-size: 16px
	line-height: 21px
	margin-top: 30px
	margin-bottom: 30px
	font-weight: normal

.panel-container
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	column-gap: 40px

.settings
	display: flex
	flex-direction: column


.input-field
	margin-bottom: 30px

.select-field
	margin-bottom: 30px
	width: 140px

.certificate-info
	font-size: 12px
	line-height: 14px
	color: $nice_color-darkgray
	margin-top: -25px

.help-info
	font-size: 12px
	line-height: 14px
	color: $nice_color-darkgray
	// margin-top: -25px

.image-info
	font-size: 12px
	line-height: 14px
	color: $nice_color-darkgray
	margin-top: 10px

.icon-uploader
	margin-top: 24px

.input-color
	display: flex
	flex-direction: row
	margin-top: 24px

.color-field
	// margin-bottom: 30px
	width: 140px

	&::v-deep .ni_input--input
		padding-left: 12px

	&::before
		content: '#'
		color: $nice_color-darkgray
		position: absolute
		bottom: 7px

.drop-icon
	width: 40px
	height: 48px

	margin-right: 20px
	padding-top: 10px

.tos-file-uploader-wrapper
	display: flex
	flex-direction: column
	justify-content: flex-end

.tos-label
	margin-bottom: 10px

.tos-textarea
	resize: none

.tos-preview
	width: 362.49px
	height: 105px

	font-style: normal
	font-weight: normal
	font-size: 10.5px
	line-height: 140%

	color: #919191

.tos-file-uploader
	height: 32px
	width: fit-content

.tos-file-preview
	font-size: 12px
	line-height: 14px
	color: $nice_color-darkgray
	margin-bottom: 10px

.dns-settings-info
	color: $nice_color-darkgray

.input--duration
	width: 132px

.duration-n-rate
	display: flex
	flex-direction: row

	.frequency-limit
		margin-left: 40px
		width: 42px

		&::before
			content: '<'
			color: $nice_color-darkgray
			position: absolute
			bottom: 7px
			left: -10px

.ad-rate__input
	display: flex
	flex-direction: row

.ad-rate__label
	margin-left: 40px

.ad-rate__modifier
	margin-left: 10px

.input--time-range
	margin-bottom: 20px
</style>
