import whitelabelDNSSettingsInfoRu from '@/locales/partials/agency/whitelabel-dns-settings-info-ru.html';
import whitelabelDNSSettingsInfoEn from '@/locales/partials/agency/whitelabel-dns-settings-info-en.html';
import whitelabelDNSSettingsInfoGe from '@/locales/partials/agency/whitelabel-dns-settings-info-ge.html';
import whitelabelDNSSettingsInfoDe from '@/locales/partials/agency/whitelabel-dns-settings-info-de.html';
// TODO: to be translated
import whitelabelDNSSettingsInfoCn from '@/locales/partials/agency/whitelabel-dns-settings-info-en.html';

export default {
	uploading: {
		en: 'Uploading...',
		ge: 'ატვირთვა...',
		de: 'Hochladen...',
		ru: 'Загрузка...',
		cn: /* TODO: to be translated */'Uploading...',
	},
	upload: {
		en: 'Upload',
		ge: 'ატვირთვა',
		de: 'Hochladen',
		ru: 'Загрузить',
		cn: /* TODO: to be translated */'Upload',
	},
	app_settings: {
		en: 'App Settings',
		ge: 'აპლიკაციის პარამეტრები',
		de: 'App-Einstellungen',
		ru: 'Настройки',
		cn: /* TODO: to be translated */'App Settings',
	},
	whitelabel_settings: {
		en: 'White-label Settings',
		ge: 'პარამეტრები',
		de: 'White-label-Einstellungen',
		ru: 'Настройки White-label',
		cn: /* TODO: to be translated */'White-label Settings',
	},
	whitelabel_dns_settings_info: {
		en: whitelabelDNSSettingsInfoEn,
		ge: whitelabelDNSSettingsInfoGe,
		de: whitelabelDNSSettingsInfoDe /* TODO: to be translated */,
		ru: whitelabelDNSSettingsInfoRu,
		cn: whitelabelDNSSettingsInfoCn,
	},
	name: {
		en: 'Agency Name',
		ge: 'სააგენტოს სახელი',
		de: 'Agenturname',
		ru: 'Название агентства',
		cn: /* TODO: to be translated */'Name',
	},
	language: {
		en: 'Language',
		ge: 'ენა',
		de: 'Sprache',
		ru: 'Язык',
		cn: /* TODO: to be translated */'Language',
	},
	default_language: {
		en: 'Default Language',
		ge: 'ნაგულისხმევი ენა',
		de: 'Standardsprache',
		ru: 'Язык по умолчанию',
		cn: /* TODO: to be translated */'Default Language',
	},
	enabled_payment_models: {
		en: 'enabled payment models',
		ge: 'ჩართული გადახდის მოდელები',
		de: 'aktivierte Zahlungsmodelle',
		ru: 'активные модели оплаты',
		cn: /* TODO: to be translated */'enabled payment models',
	},
	default_balance: {
		en: 'Default account balance',
		ge: 'Default account balance' /* TODO: to be translated */,
		de: 'Default account balance' /* TODO: to be translated */,
		ru: 'Дефолтный баланс аккаунта',
		cn: /* TODO: to be translated */'Default account balance',
	},
	domain: {
		en: 'Domain',
		ge: 'დომენი',
		de: 'Domain',
		ru: 'Домен',
		cn: /* TODO: to be translated */'Domain',
	},
	help_text: {
		en: 'Creatives can be slightly cropped to accommodate suppliers’ screen specifications. We suggest to avoid positioning logos and text close to the edges. For best results, leave a 5% margin on each side.',
		ge: 'ზომები შეიძლება ოდნავ შემცირდეს მომწოდებლების ეკრანის სპეციფიკაციის დასაკმაყოფილებლად. ჩვენ გირჩევთ მოერიდოთ ლოგოების და ტექსტის კიდეებთან ახლოს განთავსებას. საუკეთესო შედეგისთვის, დატოვეთ 5% ზღვარი თითოეულ მხარეს.',
		de: 'Kreative können leicht beschnitten werden, um den Bildschirm-Spezifikationen der Anbieter zu entsprechen. Wir empfehlen, Logos und Text nicht zu nah am Rand zu platzieren. Für beste Ergebnisse lassen Sie einen Rand von 5% auf jeder Seite.',
		ru: 'Креативы могут быть слегка обрезаны, чтобы соответствовать спецификациям экрана поставщиков. Мы рекомендуем избегать размещения логотипов и текста близко к краям. Для достижения наилучших результатов оставьте отступ 5% с каждой стороны.',
		cn: /* TODO: to be translated */'Creatives can be slightly cropped to accommodate suppliers’ screen specifications. We suggest to avoid positioning logos and text close to the edges. For best results, leave a 5% margin on each side.',
	},
	title: {
		en: 'Title',
		ge: 'სათაური',
		de: 'Titel',
		ru: 'Заголовок',
		cn: /* TODO: to be translated */'Title',
	},
	html_title: {
		en: 'HTML Title',
		ge: 'HTML სათაური',
		de: 'HTML-Titel',
		ru: 'HTML заголовок',
		cn: /* TODO: to be translated */'HTML Title',
	},
	description: {
		en: 'Description',
		ge: 'აღწერა',
		de: 'Beschreibung',
		ru: 'Описание',
		cn: /* TODO: to be translated */'Description',
	},
	visual: {
		en: 'Visual',
		ge: 'ვიზუალური',
		de: 'Visuell',
		ru: 'Внешний вид',
		cn: /* TODO: to be translated */'Visual',
	},
	main_color: {
		en: 'Main color',
		ge: 'მთავარი ფერი',
		de: 'Hauptfarbe',
		ru: 'Основной цвет',
		cn: /* TODO: to be translated */'Main color',
	},
	interface_color: {
		en: 'Interface color',
		ge: 'ინტერფეისის ფერი',
		de: 'Schnittstellenfarbe',
		ru: 'Цвет интерфейса',
		cn: /* TODO: to be translated */'Interface color',
	},
	email_settings: {
		en: 'Email settings',
		ge: 'ელფოსტის პარამეტრები',
		de: 'E-Mail-Einstellungen',
		ru: 'Настройки писем',
		cn: /* TODO: to be translated */'Email settings',
	},
	welcome_text: {
		en: 'Weclome text',
		ge: 'მისალმების ტექსტი',
		de: 'Begrüßungstext',
		ru: 'Текст приветствия',
		cn: /* TODO: to be translated */'Weclome text',
	},
	schedule_title: {
		en: 'Default schedule',
		ge: 'ნაგულისხმევი განრიგი',
		de: 'Standardschema',
		ru: 'Расписание по умолчанию',
		cn: /* TODO: to be translated */'Default schedule',
	},
	help_title: {
		en: 'Setting up contacts to help the user',
		ge: 'კონტაქტების დაყენება მომხმარებლის დასახმარებლად',
		de: 'Einrichten von Kontakten zur Unterstützung des Benutzers',
		ru: 'Настройка контактов для помощи пользователю',
		cn: /* TODO: to be translated */'Setting up contacts to help the user',
	},
	seo_title: {
		en: 'SEO optimization',
		ge: 'SEO ოპტიმიზაცია',
		de: 'SEO-Optimierung',
		ru: 'SEO оптимизация',
		cn: /* TODO: to be translated */'SEO optimization',
	},
	yandex_metrika_id: {
		en: 'Yandex Metrika ID',
		ge: 'Yandex Metrika ID',
		de: 'Yandex Metrika ID',
		ru: 'Яндекс Метрика ID',
		cn: /* TODO: to be translated */'Yandex Metrika ID',
	},
	footer: {
		en: 'Footer',
		ge: 'ფუტერი',
		de: 'Fußzeile',
		ru: 'Нижний колонтитул',
		cn: /* TODO: to be translated */'Footer',
	},
	basic: {
		en: 'Basic',
		ge: 'ძირითადი',
		de: 'Basis',
		ru: 'Базовые',
		cn: /* TODO: to be translated */'Basic',
	},
	logo: {
		en: 'Logo',
		ge: 'ლოგო',
		de: 'Logo',
		ru: 'Логотип',
		cn: /* TODO: to be translated */'Logo',
	},
	favicon: {
		en: 'Favicon',
		ge: 'მინი სურათი',
		de: 'Favicon',
		ru: 'Иконка',
		cn: /* TODO: to be translated */'Favicon',
	},
	main_image: {
		en: 'Main image',
		ge: 'მთავარი სურათი',
		de: 'Hauptbild',
		ru: 'Основное изображение',
		cn: /* TODO: to be translated */'Main image',
	},
	footer_logo: {
		en: 'Footer logo',
		ge: 'ფუტერის ლოგო',
		de: 'Fußzeilen-Logo',
		ru: 'Нижний логотип',
		cn: /* TODO: to be translated */'Footer logo',
	},
	image_error: {
		en: 'The wrong image format is selected or the dimensions do not meet the specified ones',
		ge: 'შერჩეულია სურათის არასწორი ფორმატი ან ზომები არ შეესაბამება მითითებულს',
		de: 'Das falsche Bildformat wurde ausgewählt oder die Abmessungen entsprechen nicht den angegebenen',
		ru: 'Выбран неподходящий формат изображения или размеры не соотвествуют указанным',
		cn: /* TODO: to be translated */'The wrong image format is selected or the dimensions do not meet the specified ones',
	},
	tos: {
		en: 'Terms of service caption on the registration page',
		ge: 'მომსახურების პირობების წარწერა რეგისტრაციის გვერდზე',
		de: 'Nutzungsbedingungen-Beschriftung auf der Registrierungsseite',
		ru: 'Дисклеймер про условия использования на странице регистрации',
		cn: /* TODO: to be translated */'Terms of service caption on the registration page',
	},
	tos_file_label: {
		en: 'Upload your Terms of Service',
		ge: 'ატვირთეთ თქვენი მომსახურების პირობები',
		de: 'Laden Sie Ihre Nutzungsbedingungen hoch',
		ru: 'Загрузите ваши Условия использования',
		cn: /* TODO: to be translated */'Upload your Terms of Service',
	},
	is_whitelabel_enabled: {
		en: 'Enable White-label',
		ge: 'ჩართვა White-label',
		de: 'White-label aktivieren',
		ru: 'Включить white-label',
		cn: 'Enable White-label',	// TODO: to be translated
	},
	tos_file_preview: {
		en: 'Preview TOS file',
		ge: 'TOS ფაილის გადახედვა',
		de: 'Vorschau der Nutzungsbedingungen',
		ru: 'Посмотреть файл с условиями использования',
		cn: 'Preview TOS file',	// TODO: to be translated
	},
	certificate_status: {
		en: 'HTTPS certificate status',
		ge: 'HTTPS სერტიფიკატის სტატუსი',
		de: 'HTTPS-Zertifikatsstatus',
		ru: 'статус HTTPS сертификата',
		cn: 'HTTPS certificate status',	// TODO: to be translated
	},
	certificate_status_not_generated: {
		en: 'not generated',
		ge: 'არ არის გენერირებული',
		de: 'nicht generiert',
		ru: 'не сгенерирован',
		cn: 'not generated',	// TODO: to be translated
	},
	certificate_status_generating: {
		en: 'generating',
		ge: 'გენერირება',
		de: 'wird generiert',
		ru: 'генерируется',
		cn: 'generating',	// TODO: to be translated
	},
	certificate_status_generated: {
		en: 'generated',
		ge: 'გენერირებული' /* TODO: to be translated */,
		de: 'generiert',
		ru: 'сгенерирован',
		cn: 'generated',	// TODO: to be translated
	},
	certificate_status_failed: {
		en: 'failed',
		ge: 'ჩაიშალა',
		de: 'fehlgeschlagen',
		ru: 'ошибка',
		cn: 'failed',	// TODO: to be translated
	},
	creative_docs: {
		en: 'Creative documents',
		ge: 'Creative documents',
		de: 'Creative documents',
		ru: 'Документы для креативов',
		cn: 'Creative documents',
	},
	creative_specification_label: {
		en: 'Creative specification',
		ge: 'Creative specification',
		de: 'Creative specification',
		ru: 'Спецификация для креативов',
		cn: 'Creative specification',
	},
	regulatory_docs_label: {
		en: 'Regulatory documents for creatives',
		ge: 'Regulatory documents for creatives',
		de: 'Regulatory documents for creatives',
		ru: 'Регулирующие документы для креативов',
		cn: 'Regulatory documents for creatives',
	},
};
