<template>
	<div
		class="device-filter cl-container cl-row"
		:class="{
			'loading': optionsAreLoading,
			'disabled': filterDisabled,
			[`device-filter__size-${size}`]: true,
		}"
	>

		<h2
			v-if="title"
			class="cl-pad cl-row"
			@click="showFilter=!showFilter"
		>{{ $t('ssp.filter_title') | capitalize }}</h2>

		<section
			v-show="showFilter"
			class="filters cl-container"
		>
			<!-- block A -->
			<div class="cl-container"
				:class="blockCLClasses.block"
			>
				<!-- block 1 -->
				<div class="cl-container cl-pad"
					:class="blockCLClasses.subBlock"
				>
					<!-- screen name input -->
					<nice-input
						:id="`screen-name-${u}`"
						v-model="search"
						class="cl-pad cl-row"
						type="text"
						:label="$t('ssp.screen_name') | capitalize"
						:placeholder="$t('ssp.screen_name_placeholder') | capitalize"
					/>

					<!-- address input -->
					<select-areas
						v-model="areas"
						:search-list-request="searchListRequest"
						:search-query="searchQuery"
						:error="areasErrors"
						class="cl-pad cl-row"
						@select="$emit('update:areas', $event)"
						@update:searchQuery="$emit('update:searchQuery', $event)"
					/>

				</div>

				<!-- block 2 -->
				<div class="cl-container cl-pad"
					:class="blockCLClasses.subBlock"
				>
					<!-- resolution select -->
					<nice-select
						:id="`resolution-${u}`"
						v-model="resolutions"
						class="cl-pad cl-xs4 cl-sm4 cl-md4 cl-lg4 cl-xl4"
						:placeholder="$t('choose') | capitalize"
						:label="$t('resolution') | capitalize"
						:options="options && options.resolutions"
					/>

					<!-- uptime select -->
					<nice-select
						:id="`uptime-${u}`"
						v-model="uptime"
						class="cl-pad cl-xs4 cl-sm4 cl-md4 cl-lg4 cl-xl4"
						:placeholder="$t('choose') | capitalize"
						:label="$t('inventory_modal.uptime') | capitalize"
						:options="options && uptimeOptions"
					/>

					<!-- orientation select -->
					<nice-select
						:id="`orientation-${u}`"
						v-model="orientations"
						class="cl-pad cl-xs4 cl-sm4 cl-md4 cl-lg4 cl-xl4"
						:placeholder="$t('choose') | capitalize"
						:label="$t('orientation')"
						:options="options && options.orientations"
					/>

					<!-- surface type select -->
					<nice-select
						:id="`surface-type-${u}`"
						v-model="device_types"
						class="cl-pad cl-xs4 cl-sm4 cl-md4 cl-lg4 cl-xl4"
						:placeholder="$t('choose') | capitalize"
						:label="$t('surface_type')"
						:options="options && options.surface_types"
					/>

					<!-- payment model select -->
					<!-- <nice-select
						:id="`payment-model-${u}`"
						v-model="payment_models"
						class="cl-pad cl-xs4 cl-sm4 cl-md4 cl-lg4 cl-xl4"
						label="Payment model"
						:options="options && options.payment_models"
						placeholder="Choose"
					/> -->

					<nice-checkbox
						:id="`hide-not-deployed-${u}`"
						v-model="is_deployed"
						class="cl-pad hide-not-deployed"
						:caption="$t('ssp.hide_screens_caption') | capitalize"
					/>

				</div>
			</div>

			<!-- 2 levels of div's to get the same padding as inside the filter -->
			<div v-if="!removeButtons" class="cl-container cl-row cl-pad cl-pad-right">
				<div class="filter-buttons cl-row cl-container cl-row cl-pad cl-pad-right align-end">
					<nice-button-2
						bordered
						@click="clearButtonHandler"
					>
						{{$t('clear_btn')}}
					</nice-button-2>
					<nice-button-2
						filled
						:disabled="filterDisabled"
						@click="applyButtonHandler"
					>
						{{$t('apply_btn')}}
					</nice-button-2>
				</div>
			</div>
			<div class="cl-container cl-row cl-pad cl-pad-right">
				<div class="filter-buttons cl-row cl-container cl-row cl-pad cl-pad-right align-end">
					<nice-button-2
						bordered
						@click="downloadDeviceReportButtonHandler"
					>
					{{ $t('ssp.download_device_report') }}
					</nice-button-2>
				</div>
			</div>

		</section>

	</div>
</template>

<script>
import _ from 'underscore';
import { mapState, mapActions, mapGetters } from 'vuex';

import NiceCheckbox from '@/ui/nice-checkbox.vue';
import NiceInput from '@/ui/nice-input.vue';
import NiceSelect from '@/ui/nice-select';
import NiceButton2 from '@/ui/nice-button-2';
import SelectAreas from '@/components/device/select-areas';


// TODO: check filter
export const FIELDS = [
	'search',  // name, external_id. OK
	'areas', // OK
	'resolutions', // OK
	'uptime',
	'orientations',  // OK
	'device_types',  // device_types / surface_type. OK
	'payment_models', // OK
	'is_deployed',  // OK
];

const NULL_VALUES = ['-', '---', null, ''];

const DEFAULT_VALUES = Object.freeze({
	search: '',
	areas: [],
	resolutions: '---',
	uptime: '---',
	orientations: '---',
	device_types: '---',
	payment_models: '---',
	is_deployed: null,
});


/**
 * DeviceFilter
 */
export default {
	name: 'DeviceFilter',


	// components
	components: {
		NiceCheckbox,
		NiceInput,
		NiceSelect,
		NiceButton2,
		SelectAreas,
	},


	// data
	data() {
		return {
			showFilter: true,

			initialized: false,

			// unique string ~ /[\da-f]{8}/
			u: Math.random().toString(16).slice(2, 10),

			..._.reduce(FIELDS, (setters, field) => {
				setters[field] = this.value[field] || this.default[field];
				return setters;
			}, {}),
		};
	},


	// props
	props: {
		/**
		 * title
		 * `value` is for v-model binding
		 */
		title: {
			type: String,
			required: false,
			default: '',
		},

		/**
		 * filter data
		 * `value` is for v-model binding
		 */
		value: {
			type: Object,
			required: true,
		},

		/**
		 * Default values
		 * считаются как пустые значения
		 */
		default: {
			type: Object,
			default() {
				return DEFAULT_VALUES;
			},
			required: false,
		},

		/**
		 * Query params equal filter fields
		 */
		queryParams: {
			type: Object,
			default() {
				return {
					search: 's',
					areas: 'a',
					resolutions: 'res',
					orientations: 'orient',
					device_types: 'surf',
					payment_models: 'p_model',
					is_deployed: 'hide_nd',
				};
			},
			required: false,
		},

		/**
		 * Disable whole filter
		 */
		disabled: {
			type: Boolean,
			default: false,
		},

		/**
		 * Remove buttons if necessary
		 */
		removeButtons: {
			type: Boolean,
			default: false,
		},

		/**
		 * Size prop
		 */
		size: {
			type: String,
			default: 'md',
			validator(value) {
				return ['sm', 'md', 'xl'].indexOf(value) !== -1;
			},
		},

		/**
		 * Before clear hook
		 *
		 * if returns false then filter is not cleared
		 */
		beforeClear: {
			type: Function,
			default: null
		},

		/**
		 * Before apply hook
		 *
		 * if returns false then filter is not updated
		 */
		beforeApply: {
			type: Function,
			default: null
		},

		//
		// Map areas
		//

		// TODO???
		// areas: {
		// 	type: [Array, Object],
		// },

		searchListRequest: {
			type: [Array, Object, Promise],
		},

		searchQuery: {
			type: String,
		},

		areasErrors: {
			type: [Array, Object],
		},

	},


	// computed
	computed: {
		...mapGetters('browser', { browserSize: 'size' }),
		...mapGetters('deviceFilters', { optionsAreLoading: 'isLoading' }),
		...mapState('agency', { agency: 'id' }),
		...mapState('deviceFilters', [ 'options' ]),
		...mapState('profile', { profile: 'id' }),

		uptimeOptions() {
			return this.options.uptime.map(({label, value}) => ({label: this.$t(label), value}));
		},

		/**
		 * Return cleared filter result
		 */
		result() {
			let filters = {};

			if (this.initialized) {
				_.each(FIELDS, (param) => {
					this.processParam(param, filters);
				});
			}

			return filters;
		},

		/**
		 * Computed 'disabled' flag
		 */
		filterDisabled() {
			return this.disabled || !this.agency;
		},

		blockCLClasses() {
			const size = this.size;
			const { sm, md, xl } = {
				sm: size === 'sm',
				md: size === 'md',
				xl: size === 'xl',
			};

			const block = 'cl-row';
			// {
			// 	'cl-xl6': xl || md,
			// 	'cl-lg6': xl || md,
			//
			// 	'cl-md6': xl || md,
			// 	'cl-sm6': xl || md,
			// 	'cl-xs12': xl || md,
			// 	'cl-row': sm
			// };

			const subBlock = {
				'cl-xl6': xl || md,
				'cl-lg6': xl || md,
				'cl-md6': xl || md,
				'cl-sm6': xl || md,
				'cl-xs6': xl || md,

				'cl-row': sm
			};

			return { block, subBlock };
		},
	},


	// methods
	methods: {
		...mapActions('deviceFilters', [ 'getOptions' ]),

		/**
		 * init values from get query
		 * called from created component hook
		 *
		 * @param {string} param - string equal one of field
		 */
		initParam(param) {
			const queryParam = this.queryParams[param];
			const query = this.$route.query[queryParam];

			if (query) {
				this[param] = query;
			} else if (!this.value[param]) {
				this[param] = this.default[param];
			}
		},

		/**
		 * Trigger input event
		 */
		triggerInput() {
			// let query = this.getQueryParams();
			// this.$router.push({ query: query });

			this.$emit('input', this.result);
		},

		/**
		 * Get query params in sync with the filter
		 */
		getQueryParams() {
			const data = this.result;

			let query = _.clone(this.$route.query);

			_.each(FIELDS, (param) => {
				const queryParam = this.queryParams[param];
				const value = data[param];

				if (_.isUndefined(value)) {
					delete query[queryParam];
				} else {
					query[queryParam] = value;
				}
			});

			return query;
		},

		/**
		 * Handler calc filter data
		 *
		 * `data` is changed in-place
		 */
		processParam(param, data) {
			let value = this[param];

			let availableValue;
			if (_.contains(NULL_VALUES, value)) {
				availableValue = false;
			} else {
				availableValue = true;
			}

			if (param === 'is_deployed' && value === false) {
				availableValue = false;
			}

			if (availableValue) {
				data[param] = value;
			}
		},

		/**
		 * Reset filter to initial state
		 */
		clearFilter() {
			_.each(FIELDS, (item) => {
				this[item] = this.default[item];
			});
		},

		/**
		 * Clear button handler
		 */
		clearButtonHandler() {
			if (typeof this.beforeClear === 'function') {
				if (!this.beforeClear(this.result)) {
					return;
				}
			}
			this.clearFilter();
			this.triggerInput();
			this.$emit('clear-filter', this.result);
		},

		/**
		 * Apply button handler
		 */
		applyButtonHandler() {
			if (typeof this.beforeApply === 'function') {
				if (!this.beforeApply(this.result)) {
					return;
				}
			}

			this.triggerInput();
			this.$emit('apply-filter', this.result);
		},

		downloadDeviceReportButtonHandler() {
			const ancor = document.createElement('a');
			ancor.href = this.$parent.devicesMeta.excel_url + '&report_type=base';
			ancor.setAttribute('download', '');
			ancor.className = 'visuallyhidden';
			ancor.tabIndex = -1;
			document.body.appendChild(ancor);
			ancor.click();
			document.body.removeChild(ancor);
		}
	},


	// watch
	watch: {
		/**
		 * Watch changes
		 * on changed computed result
		 * TODO: we need to limit it - apply and clear (and TODO: reset) buttons?
		 */
		result(result, oldResult) {
			if (_.isEqual(result, oldResult)) return;

			this.triggerInput();
		},

		/**
		 * Watch input event
		 * emit from triggerInput after values changed
		 */
		value(data) {
			let new_value = {
				...this.default,
				...data,
			};

			_.mapObject(new_value, (val, key) => {
				this[key] = val;
			});
		},

		/**
		 * Fire `getOptions` when if user authorized
		 */
		agency(agency, oldAgency) {
			if (agency && agency !== oldAgency && !this.options && !this.optionsAreLoading) {
				this.getOptions();
			}
		},
	},


	// hooks
	created() {
		// request filter options from API
		if (this.profile && this.agency) {
			this.getOptions();
		}

		// init fields
		_.each(FIELDS, (item) => {
			this.initParam(item);
		});
		this.initialized = true;


		// TODO: we always update a parant state
		this.triggerInput();
		this.$emit('initialized', this.result);
	},
};
</script>

<style lang="sass" scoped >
.device-filter
	margin-top: 25px

	&.loading
		opacity: .3

	h2
		margin-left: $ui-grid__mgn-x * 2

	.hide-not-deployed
		margin-top: 40px

	&.device-filter__size-md
		h2
			+ .filters
				width: 100%
				margin-top: $ui-grid__mgn-top * -1

				> .cl-container
					> .cl-pad:first-child,
					> .cl-pad:nth-child(2)
						margin-top: 0
	.filter-buttons
		button:not(:last-child)
			margin-right: 16px
</style>
