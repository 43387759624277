import _ from 'underscore';
import moment from 'moment';
import store from '@/store';

import { REQUEST_CODE_UNAUTHORIZED, token_verify, token_refresh } from '@/api/request';

function getProfile() {
	return store.state.profile;
}

/**
 * setterGenerator
 * Recieves array of field-names,
 * Returns object of setters, named `field` -> `setField`
 */
export function stateGenerator(fields) {
	return fields.reduce((state, field) => {
		state[field] = null;
		return state;
	}, {});
}


/**
 * setterGenerator
 * Recieves array of field-names,
 * Returns object of setters, named `field` -> `setField`
 */
export function setterGenerator(fields) {
	return fields.reduce((setters, field) => {
		setters[`set${capitalize(field)}`] = function(state, value) {
			if ({}.hasOwnProperty.call(state, field)) state[field] = value;
		};
		return setters;
	}, {});
}


/**
 * complex data setter
 * Recieves array of field-names,
 * Returns setter named setData
 */
export function complexDataSetter(fields) {
	return {
		setData: function (state, data) {
			for (let key in data) {
				if (fields.indexOf(key) != -1) {
					state[key] = data[key];
				}
			}
		}
	};
}


/**
 * Check if tokens exist in the session storage, and refresh if needed
 */
export async function checkAuth(store) {
	let access = store.state.profile.token;
	let refresh = store.state.profile.refresh;

	// check if role and side are OK
	if (
		store.state.app.role
		&& !store.state.app.role.startsWith(store.state.app.side)
	) {
		await store.dispatch('logout');
		return {};
	}

	// check exist tokens
	if (access && refresh) {
		let token = access;
		try {
			if (!await token_verify(token)) {
				try {
					let new_token = await token_refresh(refresh);
					store.commit('profile/setToken', { token: new_token });
				}
				catch (err) {
					if (err.response.status !== REQUEST_CODE_UNAUTHORIZED) {
						throw err;
					}

					await store.dispatch('logout');
				}
			}
		}
		catch (err) {
			store.commit('app/setErrorMessage', err.message);
		}
	}
	else {
		if (access || refresh) {
			// clear if exist one of them
			// TODO: why not both? refresh?
			store.commit('profile/setToken', { token: null });
		}

		return {};
	}
}


/**
 * formating number with char in start
 */
export function numberWithZero(num, length=2, char='0') {
	var r = '' + num;
	while (r.length < length) {
		r = char + r;
	}
	return r;
}


/**
 * Replace all
 */
export function replaceAll(string, search, replace){
	return string.split(search).join(replace);
}



/**
 * Formating Date to 25.04.2019 05:58:41
 *
 * @returns {string} html string
 */
export function formatDate(date, format='<span class="nobr" >%Y.%m.%d</span> %H:%M:%S') {
	let raw_date = date;

	try {
		if (!(date instanceof Date)) date = new Date(date);
		const bits = {
			'%Y': numberWithZero(date.getFullYear()),
			'%m': numberWithZero(date.getMonth() + 1),  // months are zero indexes
			'%d': numberWithZero(date.getDate()),
			'%H': numberWithZero(date.getHours()),
			'%M': numberWithZero(date.getMinutes()),
			'%S': numberWithZero(date.getSeconds()),
		};

		let result = format;
		_.each(bits, (v, k) => {
			result = replaceAll(result, k, v);
		});

		return result;
	}
	catch (error) {
		return raw_date;
	}
}

export function profileFormatTime(date) {
	const profile = getProfile();
	if (profile.time_format == 24) {
		return moment(date).format('HH:mm:ss');
	}
	else if (profile.time_format == 12) {
		return moment(date).format('hh:mm:ss A');
	}
}

export function profileFormatDate(date) {
	const profile = getProfile();
	if (profile.date_format == 'd/m/Y') {
		return moment(date).format('DD-MM-YYYY');
	} else if (profile.date_format == 'Y-m-d') {
		return moment(date).format('YYYY-MM-DD');
	}
}

export function profileFormatDateTime(date) {
	return profileFormatDate(date) + ' ' + profileFormatTime(date);
}


function sigFigs(value, decimal = 2) {
	if (value == 0) return 0;

	return Number(value.toPrecision(decimal));
}

export function normalizeValue(value, decimal = 2) {
	if (value > 1) {
		return Math.floor(value);
	} else {
		return sigFigs(value, decimal);
	}
}

function findDecimal(value, decimal = 2) {
	if (value == 0) return 0;

	return Math.max(0, decimal - Math.floor(Math.log10(Math.abs(value))) - 1);
}

export function normalizeDecimal(value, decimal = 2) {
	if (value < 1) {
		decimal = findDecimal(value);
	}
	return decimal;
}


/**
 * Round with float
 */
export const coolRound = (value, decimal=0) => +(Math.round(value + `e+${decimal}`)  + `e-${decimal}`);


/**
 * Formating cash
 */
export function formatCash(cash, currency, decimal = 0, separator = '&nbsp;', separatorDecimal = '.') {
	decimal = normalizeDecimal(cash);
	return `${formatNumber(cash, decimal, separator, separatorDecimal)}${currency ? separator+formatCurrency(currency) : ''}`;
}

/**
 * Formating cash spent
 */
export function formatCashSpent(cash_spent, cash_total, currency, decimal=0, separator='&nbsp;', separatorDecimal='.') {

	let cachSpentFormated = formatCash(cash_spent, null, decimal, separator, separatorDecimal);
	let cachTotalFormated = formatCash(cash_total, currency, decimal, separator, separatorDecimal);

	return `${cachSpentFormated} / ${cachTotalFormated}`;
}


/**
 * Formating number
 */
export function formatNumber(number, decimal=0, separator='&nbsp;', separatorDecimal='.') {
	const negativeSign = number < 0 ? '-' : '';
	let [intPart, floatPart] = coolRound(number, decimal).toString().split('.');

	const mainPart = intPart.length % 3;
	intPart = (mainPart ? `${intPart.substr(0, mainPart)}${intPart.length > 3 ? separator : ''}` : '') + intPart.substr(mainPart).replace(/(\d{3})(?=\d)/g, `$1${separator}`);
	return `${negativeSign}${intPart}${floatPart ? separatorDecimal+floatPart : ''}`;
}


/**
 * Formating currency
 *
 * TODO: localize
 */
export function formatCurrency(currency) {
	switch(currency) {
	case 'USD':
		return '$';

	case 'RUB':
		return '₽';

	case 'EUR':
		return '€';

	default:
		return currency;
	}
}


/**
 * Formating number with metric postfix
 *
 * Y	1000000000000000000000000
 * Z	1000000000000000000000
 * E	1000000000000000000
 * P	1000000000000000
 * T	1000000000000
 * G	1000000000
 * M	1000000
 * k	1000
 * h	100
 * da	10
 */
const units = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
export function formatMetric(value, decimal=1, separator='&nbsp;', separatorDecimal='.') {
	try {
		const poop = Math.ceil(value.toString().length/3) - 1;
		return `${formatNumber(value/Math.pow(1000, poop), decimal, separator, separatorDecimal)}${poop ? separator : ''}${units[poop]}`;
	}
	catch (e) {
		// TODO: ???
		return value;
	}
}


/**
 * Capitalize string
 */
export function capitalize(str) {
	return str[0].toUpperCase() + str.slice(1);
}


/**
 * Detect available or not page to user
 *
 * TODO:
 *  * rename?
 *
 * @param {string} user acces type - ssp / dsp / guest
 * @param {object} Route
 *
 * @returns {boolean}
 */
export function userAccessToRoute(access, route) {
	return route.meta && _.intersection(route.meta.access, [access, 'all']).length;
}

export function routeAllowsRole(route, role) {
	return route.meta && _.intersection(route.meta.roles, [role, 'all']).length;
}


/**
 * Camel-Case hange to Snake-Case
 *
 * @param {string} input string in Camel-Case
 * @param {object} separator (default: '-')
 *
 * @returns {string} out string in Snake-Case
 */
export function camelToSnake(string, separator='-') {
	return string.replace(/([A-Z][a-z]*|\d+)/g, (c,f,i) => `${i ? separator : ''}${c.toLowerCase()}`);
}


function _objectToQueryGenKey(key, prefix='') {
	return encodeURIComponent(prefix ? `${prefix}[${key}]` : key);
}

/**
 * Convert Object to Get query
 *
 * TO-DO:
 * * convert arrays
 *
 * @param {object} object - input flat object to convert to query
 * @param {boolean} add_separator - default: true - flag to add prefix with ? sign
 *
 * @returns {string} out query
 */
export function objectToQuery(object, add_separator=true, prefix='') {
	let query = _.reduce(object, (result, value, k) => {
		const key = _objectToQueryGenKey(k, prefix);
		return result + `&${key}=${encodeURIComponent(value)}`;
	}, '').slice(1);
	return `${add_separator && query ? '?' : ''}${query}`;
}





/**
 * Formats video duration
 * @param {number} duration - number of seconds
 * @return {string} formated time string
 */
export function formatDuration(duration) {
	let timeString = '';

	if (!duration) {
		return timeString;
	}

	// seconds
	timeString = (duration % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 }) + timeString;

	//minutes
	duration = Math.floor(duration / 60);
	timeString = (duration % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' + timeString;

	//hours
	duration = Math.floor(duration / 60);
	if (!duration) return timeString;
	timeString = (duration % 24).toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' + timeString;

	// days
	duration = Math.floor(duration/24);
	if (!duration) return timeString;
	timeString = (duration).toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' + timeString;

	return timeString;
}

/**
 * Formats file size
 * @param {[number]} size - size in bytes
 * @return {[string]} formatted file size like `18&nbsp;mb`
 */
export function formatFileSize(size, safe=true) {
	const UNITS = ['b', 'kb', 'mb', 'gb', 'tb'];
	let unitIndex = 0;
	while (size > 512 && unitIndex < UNITS.length - 1) {
		size = size / 1024;
		unitIndex++;
	}
	size = parseFloat(size.toFixed(2));
	return `${ size }${ safe ? ' ' : '&nbsp;' }${ UNITS[unitIndex] }`;
}


/**
 * Return filter for devices viewset
 *
 * Used for map, devices, request/campaign stats
 *
 * @params {Object} instance - Request/Campaign-like instance object
 */
export function getDevicesFilter(instance, deployed_only = false) {
	let filter = {};

	const listFields = [
		'device_types', 'environments', 'intents', 'interests', 'life_stages',
		'orientations', 'resolutions', 'payment_models', 'time_range',
		'platforms',
	];

	// simple data
	const commonFields = [
		'search', 'industry', 'advertiser', 'start_date',
		'end_date', 'ad_duration', 'payment_model',
		'max_bid', 'budget', 'is_deployed', 'ad_rate_per_hour', 'campaign_id',
		'pois', 'uptime',
	];

	let inst = _.clone(instance);

	_.each(inst, (value, key) => {
		// if value contains 'all' then don't send field
		if (_.contains(listFields, key) && !_.contains(value, 'all') && value.length > 0) {
			// HACK:
			// resolutions may be a common field
			if (!(value instanceof Array)) {
				filter[key] = value;
			} else {
				filter[key] = value.join(',');
			}
		}

		if (_.contains(commonFields, key) && value) {
			filter[key] = value;
		}

		if (key == 'age' && value.length == 2) {
			filter.age_min = value[0];
			filter.age_max = value[1];
		}

		if (key == 'gender') {
			filter.gender = value;

			// default - all
			// filter.male_percentage_min = 0;
			// filter.male_percentage_max = 100;

			if (value == 'male') {
				// is there male auditory at all?
				filter.male_percentage_min = 1;
			}

			if (value == 'female') {
				// is there female auditory at all?
				filter.male_percentage_max = 99;
			}
		}

		if (key == 'areas') {
			let areas = _.clone(value);
			areas.sort((i0, i1) => i0.sorting < i1.sorting);

			let areasStr = _.reduce(areas, (a, i) => {
				a.push(`${i.longitude}x${i.latitude},${i.radius},${i.action}`);
				return a;
			}, []).join(';');

			if (areasStr) {
				filter[key] = areasStr;
			}
		}

		if (key == 'id') {
			filter['campaign_id'] = inst['id'];
		}

		// let's use a list - this way it is easier to write DeviceFilter on the backend
		if (key == 'schedule') {
			filter[key] = ([
				value['mon'],
				value['tue'],
				value['wed'],
				value['thu'],
				value['fri'],
				value['sat'],
				value['sun'],
			]).join(',');
		}
	});

	if (deployed_only) {
		filter['is_deployed'] = true;
	}

	return filter;
}


/**
 * Parse jwt token
 *
 * @param {string} JWT token
 *
 * @returns {object} parsed JWT token
 */
export function parseJwt(token) {
	if (!token) { return null; }
	const base64Url = token.split('.')[1];
	const base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	const result = JSON.parse(base64);
	return {
		...result,
		exp: new Date(result.exp*1000),
	};
}


export function getInitials(string) {
	if (typeof string === 'number') {
		string = '' + string;
	}
	if (!string) {
		return '';
	}

	const separator = /[\s./\\()"'-:,.;<>~!@#$%^&*|+=[\]{}`~?]+/igm;
	const segments = String(string).trim().split(separator);

	if (!segments.length) {
		return '';
	}

	let initials = segments[0].charAt(0);

	if (segments.length > 1) {
		initials += segments[segments.length - 1].charAt(0);
	}

	return initials;
}



/**
 * Safe copy to clipboard
 * https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
 *
 * @param      {string}  str     The string
 */
export function copyToClipboard (str) {
	const el = document.createElement('textarea');  // Create a <textarea> element
	el.value = str;                                 // Set its value to the string that you want copied
	el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
	el.style.position = 'absolute';
	el.style.left = '-9999px';                      // Move outside the screen to make it invisible
	document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
	const selected =
		document.getSelection().rangeCount > 0        // Check if there is any content selected previously
			? document.getSelection().getRangeAt(0)     // Store selection if found
			: false;                                    // Mark as false to know no selection existed before
	el.select();                                    // Select the <textarea> content
	document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
	document.body.removeChild(el);                  // Remove the <textarea> element
	if (selected) {                                 // If a selection existed before copying
		document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
		document.getSelection().addRange(selected);   // Restore the original selection
	}
}


/**
 * Convert string to float
 * support many input formats:
 * * 1,000
 * * 1,123,000.00
 * * 1,00
 * * 1,000.00
 * * 1 000,00
 * * 1 000.00
 * * 1000,00
 * * 1000.00
 *
 * @param      {string}  str     The string
 * *
 * @returns {number} parsed float
 */
export function convertStringToFloat(str) {
	const regex = /^\d+(([ ,]\d{3})*)([.,]\d{1,2})?$/g;

	function replacer(match, p1, p2, p3) {
		let result = '';
		if (p3) {
			result = match.replace(p1+p3, '')+p1.replaceAll(/[ ,]/g, '')+p3.replace(',', '.');
		} else {
			result = match.replace(p1, '')+p1.replaceAll(/[ ,]/g, '');
		}
		return result;
	}

	return str.replace(regex, replacer);
}


export function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}


export function scrollToElementTop(el) {
	window.scrollTo({
		left: 0,
		top: getPageTopOffset(el),
		behavior: 'smooth',
	});
}

export function getPageTopOffset(el) {
	let top = el.offsetTop;
	let parent = el.offsetParent;

	while (parent) {
		 top += parent.offsetTop;
		 parent = parent.offsetParent;
	}

	return top;
}


/**
 * Mapbox features have `context`, eg.
 *
 *	{
 *		context: {
 *			{ id: "postcode.<id>", text: "..."},
 *			{ id: "place.<id>", text: "..."},
 *			{ id: "region.<id>", text: "..."},
 *			{ id: "country.<id>", text: "..."},
 *			...
 *	  },
 *		...
 *	}
 *
 */
export function getGeofeatureNamePart(feature, part) {
	for (let f of feature.context) {
		if (f.id.startsWith(`${part}.`)) {
			return f.text;
		}
	}

	return null;
}


/**
 * Our wrapper to replace `feature.place_name`
 */
export function getGeofeatureName(feature) {
	let parts = [
		getGeofeatureNamePart(feature, 'postcode'),
		getGeofeatureNamePart(feature, 'place'),
		getGeofeatureNamePart(feature, 'region'),
		feature.text,
	];

	return parts.filter(i => i).join(', ');
}
