import { render, staticRenderFns } from "./users-list-table.vue?vue&type=template&id=55f52682&scoped=true"
import script from "./users-list-table.vue?vue&type=script&lang=js"
export * from "./users-list-table.vue?vue&type=script&lang=js"
import style0 from "./users-list-table.vue?vue&type=style&index=0&id=55f52682&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f52682",
  null
  
)

export default component.exports