import { render, staticRenderFns } from "./warn.vue?vue&type=template&id=a666afb2&scoped=true"
import script from "./warn.vue?vue&type=script&lang=js"
export * from "./warn.vue?vue&type=script&lang=js"
import style0 from "./warn.vue?vue&type=style&index=0&id=a666afb2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a666afb2",
  null
  
)

export default component.exports